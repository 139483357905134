.filter-buttons {
  background-color: var(--color-n100);
  padding: 4px;
  display: flex;
  border-radius: 10px;
  margin-top: 4px;

  .filter-button {
    flex: 1;
    font-size: 12px;
    line-height: 1.43;
    font-weight: 600;
    padding: 4px;
    color: var(--color-n500);
    border-radius: 6px;

    &.selected {
      color: var(--color-n900);
      background-color: var(--color-n000);
      border: 1px solid var(--color-n200);
      box-shadow: 0px 1px 2px 0px rgba(27, 28, 29, 0.05);
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
