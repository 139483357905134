.close-checklist {
  .column-header {
    border-bottom: solid 1px var(--color-n200);
    padding-bottom: var(--spacing-md);

    .content-section {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
    }

    .filter-buttons {
      margin-top: 0;
      flex: 1;
    }

    .filter-buttons-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .settings-icon-container {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: var(--spacing-md);
      border-radius: 6px;

      &:hover {
        background-color: rgba(45, 54, 102, 0.05);
      }
    }

    .meta-data-loading {
      display: flex;

      .checklist-review-loading-skeleton {
        flex: 1;
        margin-bottom: calc(var(--spacing-xl));

        &:first-child {
          margin-right: calc(var(--spacing-md));
        }

        &:last-child {
          margin-left: calc(var(--spacing-md));
        }
      }
    }

    .meta,
    .val {
      display: flex;
      flex-direction: column;
    }

    .meta {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .label {
      font-size: 10px;
      line-height: 1.4;
      color: var(--color-n500);
      font-weight: 400;
    }

    .val {
      font-size: var(--font-size-sm);
      line-height: 1.5;
      color: var(--color-n800);
      font-weight: 600;
    }

    .loan-number {
      font-size: 16px;
      font-weight: 700;
      color: var(--color-n800);
      margin-bottom: var(--spacing-md);
      line-height: 24px;
    }
  }

  .organizational-tags-pill {
    display: flex;
    height: 22px;
    padding: 2px 6px;
    align-items: center;
    gap: var(--spacing-xs);

    border-radius: 9999px;
    border: 1px solid rgba(83, 120, 253, 0.25);
    background: var(--color-b100);

    color: var(--color-b500);
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    width: fit-content;
    max-width: 100%;
  }
  .pill-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .group-icon {
    flex-shrink: 0;
  }

  .organizational-tags-container {
    padding: var(--spacing-md) var(--spacing-md) 0;
  }
}
