// NovaPrime Design System Styles

// If root em is 16px so base is 8
$sizeBase: 0.5rem;

// This is required if we use REM values anywhere in the app, REM units are root em, the root being the font-size on the html root element
html {
  font-size: 16px;
}

// Build CSS Vars
$sizePrefix: --size-;
$sizeSteps: (
  collapse: 0,
  6xs: (
    $sizeBase * 0.25
  ),
  5xs: (
    $sizeBase * 0.5
  ),
  4xs: (
    $sizeBase * 0.75
  ),
  3xs: (
    $sizeBase
  ),
  xxs: (
    $sizeBase * 1.25
  ),
  xs: (
    $sizeBase * 1.5
  ),
  sm: (
    $sizeBase * 1.75
  ),
  md: (
    $sizeBase * 2
  ),
  lg: (
    $sizeBase * 3
  ),
  xl: (
    $sizeBase * 4
  ),
  xxl: (
    $sizeBase * 5
  ),
  3xl: (
    $sizeBase * 6
  ),
  4xl: (
    $sizeBase * 7
  )
);
:root {
  @each $name, $sizeStep in $sizeSteps {
    #{$sizePrefix}#{$name}: $sizeStep;
  }
}

// Output if root em is 16px
/*----------------------------------
--size-collapse: 0;
--size-6xs: 2px;
--size-5xs: 4px;
--size-4xs: 6px;
--size-3xs: 8px;
--size-xxs: 10px;
--size-xs: 12px;
--size-sm: 14px;
--size-md: 16px;
--size-lg: 24px;
--size-xl: 32px;
--size-xxl: 40px;
--size-3xl: 48px;
--size-4xl: 56px;
---------------------------------*/

// Basic helper classes

// Spacing: always push down and to the right so this is margin-bottom and margin-right to create vertical and horizontal rhythms
// Horizontal spacing
.hs {
  &-xxs {
    margin-right: var(--size-xxs);
  }
  &-xs {
    margin-right: var(--size-xs);
  }
  &-sm {
    margin-right: var(--size-sm);
  }
  &-md {
    margin-right: var(--size-md);
  }
  &-lg {
    margin-right: var(--size-lg);
  }
  &-xl {
    margin-right: var(--size-xl);
  }
  &-xxl {
    margin-right: var(--size-xxl);
  }
}

// Vertical spacing
.vs {
  &-xxs {
    margin-bottom: var(--size-xxs);
  }
  &-xs {
    margin-bottom: var(--size-xs);
  }
  &-sm {
    margin-bottom: var(--size-sm);
  }
  &-md {
    margin-bottom: var(--size-md);
  }
  &-lg {
    margin-bottom: var(--size-lg);
  }
  &-xl {
    margin-bottom: var(--size-xl);
  }
  &-xxl {
    margin-bottom: var(--size-xxl);
  }
}

// Font size
.fs {
  &-xxs {
    font-size: var(--size-xxs);
  }
  &-xs {
    font-size: var(--size-xs);
  }
  &-sm {
    font-size: var(--size-sm);
  }
  &-md {
    font-size: var(--size-md);
  }
  &-lg {
    font-size: var(--size-lg);
  }
  &-xl {
    font-size: var(--size-xl);
  }
  &-xxl {
    font-size: var(--size-xxl);
  }
}

// Text color
.color {
  &-base {
    color: var(--color-n800);
  }
  &-light {
    color: var(--color-n500);
  }
}
