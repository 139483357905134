@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: local('Source Sans Pro'), url(./fonts/SourceSansPro-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  src: local('Source Sans Pro'), url(./fonts/SourceSansPro-ExtraLightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Sans Pro'), url(./fonts/SourceSansPro-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: local('Source Sans Pro'), url(./fonts/SourceSansPro-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro'), url(./fonts/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: local('Source Sans Pro'), url(./fonts/SourceSansPro-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Sans Pro'), url(./fonts/SourceSansPro-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: local('Source Sans Pro'), url(./fonts/SourceSansPro-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Source Sans Pro'), url(./fonts/SourceSansPro-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: local('Source Sans Pro'), url(./fonts/SourceSansPro-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: local('Source Sans Pro'), url(./fonts/SourceSansPro-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  src: local('Source Sans Pro'), url(./fonts/SourceSansPro-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans 3';
  src: local('Source Sans 3'), url(./fonts/SourceSans3-VariableFont_wght.ttf) format('truetype');
}

html {
  min-height: 100vh;
  background-color: #fff;
  scrollbar-color: #989eb5b2 #fff;
  overflow: hidden;
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 22px;
  height: 22px;
}
::-webkit-scrollbar-thumb {
  background: #989eb5b2;
  border-radius: 26px;
  border: 5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

::-webkit-scrollbar-track:horizontal {
  border-top: 1px solid var(--color-n300);
}

*:focus {
  outline-color: #444f78;
}

// Color of cell flash in aggrid
.ag-row .ag-cell-data-changed {
  background-color: #faffd4 !important;
}

.tool-tip {
  display: flex;
  border-radius: 4px;
  width: fit-content;
  height: fit-content;
  max-width: 500px;
  padding: 10px 12px;
  color: #444f78;
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  z-index: 100;
  white-space: pre-wrap;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccd1de;
  overflow: visible;
  box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.15);
}

/*
  Needed to display popover over the panel
  Also necessary in index.scss for global apply
  Popover is placed at root level
*/
.react-tiny-popover-container {
  z-index: 1000;
}

.bulk-panel-action {
  order: 1;
  padding-left: 0.625rem;
  border-left: 1px solid var(--color-n300);
}

* {
  // Spacing
  --spacing-xs: 4px;
  --spacing-md: 8px;
  --spacing-lg: 12px;
  --spacing-xl: 16px;
  --spacing-2xl: 20px;

  // Font Sizes
  --font-size-sm: 12px;
  --font-size-base: 14px;
  --font-size-lg: 16px;
  --font-size-3xl: 24px;

  // radius ?
  --radius-full: 9999px;

  // colors
  --color-n000: #ffffff;
  --color-n100: #f7f8fa;
  --color-n150: #f1f3f5;
  --color-n200: #eceef3;
  --color-n300: #ccd1de;
  --color-n350: #b4b9c9;
  --color-n400: #989eb5;
  --color-n500: #8187a6;
  --color-n600: #616d98;
  --color-n700: #38447f;
  --color-n800: #444f78;
  --color-n900: #2d3666;

  --color-b100: #eef2ff;
  --color-b200: #d5d9eb;
  --color-b300: #bdcbff;
  --color-b400: #8ba5ff;
  --color-b500: #5378fd;
  --color-b600: #355ce5;
  --color-b650: #003fd6;
  --color-b700: #363f72;
  --color-b900: #2d3666;

  --color-g100: #ecfaf2;
  --color-g200: #d6f9e5;
  --color-g300: #9bd6bd;
  --color-g400: #abefc6;
  --color-g500: #3acc7d;
  --color-g700: #04985a;
  --color-g750: #037a48;
  --color-g800: #067647;

  --color-y500: #e7f256;

  // TODO confirm new color
  --color-r050: #fef3f2;
  --color-r100: #ffeef0;
  --color-r300: #ffb7c0;
  --color-r500: #ff4c61;
  --color-r550: #cc3d4e;
  --color-r700: #db001a;

  --color-o050: #fffaf6;
  --color-o100: #fff5ee;
  --color-o200: #ffdbc2;
  --color-o400: #fcb480;
  --color-o500: #ff8d3a;
  --color-o700: #ee6400;

  --color-p050: #f4f3ff;
  --color-p200: #d9d6fe;
  --color-p700: #5925dc;

  // success colors
  --color-s050: #ecfdf3;
  --color-s200: #abefc6;
  --color-s500: #17b26a;

  // warning colors
  --color-w050: #fffaeb;
  --color-w200: #fedf89;
  --color-w400: #fdb022;
  --color-w500: #f79009;
  --color-w700: #b54708;

  // error colors
  // TODO confirm new color
  --color-e100: #f00000;
  // TODO confirm new color
  --color-e500: #f04438;
  --color-e700: #b42318;

  // success colors
  // TODO confirm new color
  --color-s050: #ecfdf3;
  --color-s600: #079455;

  // text
  --text-main-900: #0a0d14;
  --text-secondary: #515981;
  --text-primary: #21274a;
  --text-tertiary: var(--color-n500);
  --text-error: var(--color-e700);
  --text-warning: var(--color-w700);
  --text-success: var(--color-g800);

  --border-secondary: var(--color-n300);
  --border-tertiary: var(--color-n200);

  // Background
  --primary-lighter: #ebf1ff;

  // Box Shadows
  --shadow-sm: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

  // utility
  --color-u50: #f9fafb;
  --color-u200: #eaecf0;
  // TODO confirm new color
  --color-u300: #cdd0d5;
  --color-u700: #344054;

  --color-ub100: #e0f2fe;
  --color-ub200: #b2ddff;
  --color-ub050: #eff8ff;
  --color-ub600: #0086c9;
  --color-ub700: #175cd3;

  --color-upurple100: #ebe9fe;
  --color-upurple600: #6938ef;

  --color-upink100: #fbe7f6;
  --color-upink600: #dd2690;

  --color-o100: #fdf5ef;
  --color-o600: #f0934d;

  --color-border-light: #6938ef1a;
  --color-border-primary: #d0d5dd;
  --color-border-error: #fecdca;

  --modal-backdrop-color: #444f784d;

  //  Components
  --global-header-offset: 68px;
  --background-tooltip: var(--color-n900);
}
