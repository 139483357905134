.sidebar-navigation {
  --sidebar-width: 211px;

  $itemLineHeight: 20px;
  $itemIconSize: 16px;
  user-select: none;

  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 0 0 var(--sidebar-width);
  justify-content: space-between;
  border-right: 1px solid #e2e4e9;

  .company-logo {
    width: var(--sidebar-width);
    height: 66px;
  }

  .action-item,
  .panel-item {
    padding: 6px var(--spacing-md);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: $itemLineHeight;
  }

  .navigation-panels {
    display: flex;
    flex-direction: column;
    gap: 32px;
    color: var(--color-n500);
    padding: var(--spacing-2xl) var(--spacing-xl);

    .panel-title {
      padding: var(--spacing-xs);
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.48px;
    }

    .panel-items {
      margin: 6px 0;
      display: flex;
      flex-direction: column;
      gap: var(--spacing-xs);
    }

    .panel-item {
      $iconGap: var(--spacing-md);
      align-items: center;

      display: flex;
      gap: $iconGap;

      &:hover,
      &.active {
        color: var(--text-main-900);
        border-radius: var(--spacing-md);
        background-color: var(--primary-lighter);
      }

      // To show nested items within a link
      &.nested {
        margin-left: calc($itemIconSize + $iconGap);
        gap: var(--spacing-xs);
      }

      &.active:before {
        left: 0;
        content: '';
        height: $itemLineHeight;
        position: fixed;
        width: var(--spacing-xs);
        background-color: var(--color-b500);
        border-radius: 0 var(--spacing-xs) var(--spacing-xs) 0;
      }

      &.not-actionable {
        &:hover,
        &.active {
          background-color: inherit;
        }
      }
    }

    .panel-item-icon {
      width: $itemIconSize;
      height: $itemIconSize;
    }
  }

  .sidebar-actions {
    gap: 6px;
    display: flex;
    flex-direction: column;
    padding: var(--spacing-xl);

    .action-item {
      display: flex;
      flex-direction: row;
      gap: var(--spacing-md);
      align-items: center;
      justify-content: flex-start;

      color: var(--text-main-900);

      &:hover {
        border-radius: var(--spacing-md);
        background-color: var(--primary-lighter);

        .action-icon {
          border: 1px solid var(--color-n300);
        }
      }

      &.danger {
        &:hover {
          color: var(--color-r500);
          background-color: var(--color-r100);
        }
      }
    }

    .action-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 42px;
      height: 42px;
      border-radius: 50%;
      color: var(--color-n500);
      background-color: var(--color-n200);
    }
  }

  .number-badge {
    margin-left: auto;
  }

  .selected-loan {
    color: var(--color-n900);
  }
}
