.sidebar-entry-action {
  cursor: pointer;
}

.user-logout-menu-popover {
  margin: var(--spacing-lg);
}

.sidebar-user-menu-logout-entry {
  color: var(--color-r500);
}
