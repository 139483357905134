.action-toast-content {
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
  margin-left: var(--spacing-xs);

  .text-semibold {
    font-weight: 600;
  }

  .action-toast-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    padding: 4px 10px;
    color: white;
    background: var(--color-b500);
    font-size: var(--font-size-sm);
    line-height: 1.5;
    font-weight: 600;
    white-space: nowrap;
    user-select: none;

    &:hover {
      background-color: var(--color-b600);
    }

    &:active {
      background: var(--color-b500);
      box-shadow: 0px 0px 0px 1px #fff, 0px 0px 0px 2px rgba(29, 78, 216, 0.25);
    }
  }
}
