.loading-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: calc(var(--spacing-md) + var(--spacing-lg) / 2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;

  &.container-bounds {
    position: absolute;
  }

  &.container-height {
    height: 100%;
  }

  &.container-width {
    width: 100%;
  }

  .loading-spinner {
    display: inline-block;
    min-height: 2rem;
    max-height: 2rem;
    min-width: 2rem;
    max-width: 2rem;
    animation: loadingSpinnerAnimation 1s linear infinite;
    border-radius: 50%;
    border: var(--spacing-xs) solid;
    border-color: var(--color-n800);
    border-right-color: transparent;
  }

  .loading-spinner-text {
    font-size: 14px;
    font-weight: 600;
  }
}

@keyframes loadingSpinnerAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
